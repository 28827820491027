.head-div {
  border: 2px dotted #608e7e;
  background: #effff9;
  width: 100%;
  height: 277px;
  border-radius: 24px;
  border-width: 2px;
  padding: 10px;
  .w100 {
    width: 100%;
  }
  .h100 {
    height: 100%;
  }
  img {
    border-radius: 14px;
  }
  .event-img {
    width: 252px;
    height: 252px;
    border-radius: 16px;
  }
  .event-title {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    letter-spacing: 0%;
  }
  .total-tickets {
    color: #737373;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0%;
  }
  .total-price {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0%;
  }
}
.input-div-set {
  min-width: 395px;
  padding: 0 20px 0 10px;
}
.input-div {
  min-width: 375px;
}

.select-div {
  width: 80px !important;
}
.select-input-div {
  min-width: 290px;
}
.w100 {
  width: 100%;
}
.ticket-name {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: #475367;
}
.ticket-price {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  color: #475367;
}
.details-div {
  min-width: 375px;
  height: 100%;
}
@media (min-width: 576px) {
  /* Adjust the breakpoint as needed */
  .details-div {
    min-width: 375px;
    border-left: 1px solid #949494;
    height: 100%;
    padding: 0 20px 10px 20px;
  }
}
.onepay-logo {
  max-width: 164px;
  height: auto;
}
.payment-option-logo {
  max-width: 870px;
  height: auto;
}
@media (max-width: 576px) {
  .input-div-set {
    width: 100%;
  }
  .input-div {
    min-width: 100%;
  }
  .select-div {
    width: 20%;
    margin-right: 10px;
  }
  .select-input-div {
    width: 80%;
  }
  .details-div {
    width: 100%;
    padding: 10px 10px 10px 10px;
  }
  .onepay-logo {
    max-width: 85px;
    height: auto;
    margin-bottom: 10px;
  }
  .payment-option-logo {
    max-width: '80%';
    height: auto;
    margin-bottom: 10px;
  }
}
.total-price {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  letter-spacing: 0%;
  text-align: right;
  vertical-align: middle;
  color: #1d2739;
}
.sub-title {
  min-width: 375px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  color: #051b13;
  padding: 5px 0 5px 0;
  border-bottom: 1px solid #e4e7ec;
  margin-bottom: 10px;
}
.form-check-input:checked {
  background-color: green;
  border-color: green;
}

.back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 8px;
  border-width: 1px;
  gap: 10px;
  padding-top: 16px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  border: 1px solid var(--Stroke, #d0d5dd);
  cursor: pointer;
}
.proceed-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 54px;
  border-radius: 6px;
  gap: 10px;
  border-bottom-width: 1px;
  padding: 16px;
  background-color: #1e1e1e;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 20px;
  line-height: 145%;
  letter-spacing: 0%;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  cursor: pointer;
}
