.event {
  .banner-wrapper {
    // height: 22rem;
    height: 336px;
    width: 293px;
    overflow: hidden;
    border-radius: 8px;
  }

  .active_label {
    padding: 2px 10px;
    background: #c5ffd2;
    color: #00643f;
    text-align: center;
    border: 1px solid #00643f;
    border-radius: 36px;
    font-size: 12px;
  }
  .active_label_d {
    padding: 2px 10px;
    background: #c5d4ff;
    color: #002664;
    text-align: center;
    border: 1px solid #002664;
    border-radius: 36px;
    font-size: 12px;
  }
  .location_display {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px;
  }

  .location_image {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .location_text {
    font-size: 13px;
    font-weight: 400;
    color: #636363;
  }
  .inline-container {
    display: flex;
    justify-content: space-between; /* Creates space between elements */
    align-items: end;
    width: 293px;
  }

  .price-text {
    font-size: 18px;
    font-weight: 600;
  }
  .upward {
    font-size: 10px;
    font-weight: 400;
    color: #636363;
  }

  button.primary-btn,
  button.buy-btn-disable {
    margin-left: 8px; /* Optional space to right of price */
  }

  .banner {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 0.5s ease-out;
  }

  .banner-wrapper:hover,
  .banner-wrapper:focus {
    .banner {
      transform: scale(1.1);
    }
  }

  h3 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.188rem;
    color: #000000;
  }

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.875rem;
    color: #515151;
  }

  .buy-btn {
    font-size: 13px;
    padding: 0.5rem 0.938rem;
  }
  .buy-btn-disable {
    padding: 0.5rem 0.938rem;
    background-color: #999797;
    color: aliceblue;
    border-radius: 6px;
  }
  @media (max-width: 576px) {
    .banner-wrapper {
      width: 100%;
    }
    .inline-container {
      width: 100%;
    }
  }
}
