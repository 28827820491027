.events {
  position: relative;
  z-index: 100;

  &-1 {
    
    .heading {
      font-weight: 700;
      font-size: 2.825rem;
      line-height: 4rem;
      color: $color-black-2;

      @include respond(w1200) {
        font-size: 2.325rem;
        line-height: 3.2rem;
      }

      @include respond(w992) {
        font-size: 2.325rem;
        line-height: 3.1rem;
      }

      @include respond(w576) {
        font-size: 2.225rem;
        line-height: 3rem;
      }
    }

    .heading > .colured {
      background-image: linear-gradient(
        270deg,
        #4ae1eb 0%,
        #99ee43 37.38%,
        #ffab5e 71%,
        #e578ba 89%
      );
      background-size: 100%;
      background-repeat: repeat;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
    }

    p {
      font-weight: 500;
      font-size: 1.375rem;
      line-height: 1.875rem;
      color: #545454;
    }

    .search {
      .input {
        position: relative;

        img {
          position: absolute;
          top: 50%;
          left: 1.25rem;
          transform: translateY(-50%);
        }

        input {
          border-radius: 8px !important;
          background: $color-white;
          border: 1px solid #2d2d2d;
          padding: 0.625rem 0.625rem 0.625rem 3.125rem;

          &:focus {
            border: 1px solid $color-black;
            box-shadow: none;
          }
        }
      }

      button {
        padding: 0.713rem 1.625rem;
      }
    }
  }

  &-2 {
    margin-top: 10rem;

    @include respond(w576) {
      margin-top: 5rem;
    }

    &-1 {
      h6 {
        font-weight: 600;
        font-size: 1.75rem;
        color: #252525;
        margin-bottom: 0;
      }

      p {
        font-weight: 500;
        font-size: 1rem;
        color: #4e4e4e;
      }
    }

    .slide-list {
      width: 100%;
      padding: 0 10px; /* Adjust this value for desired spacing */
      box-sizing: border-box; /* Ensures padding doesn’t affect layout */
    }

    // .slick-slide {
    //   margin: 0 5px; /* Additional margin between slides if needed */
    // }
    .list {
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // grid-template-rows: auto;
      // column-gap: 6.875rem;
      // row-gap: 3.125rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      // column-gap: 6.875rem;
      column-gap: 2.875rem;
      row-gap: 3.125rem;

      @include respond(w992) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include respond(w768) {
        grid-template-columns: 1fr;
      }

      @include respond(w576) {
        column-gap: 0;
      }
    }
  }
  
}
.category-item {

  &-selected {
    background-color: #d6ffdf;
    border: 1px solid #00643f;
    color: #00643f;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    min-width: 105px;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  &-unselected {
    background-color: #ffffff;
    border: 1px solid #8e8e8e;
    color: #252525;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    min-width: 105px;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  }
}
.cover {
  position: relative;
  width: 100%;
  z-index: 50;

  .i1 {
    position: absolute;
    top: -53px;
    left: 26%;
  }

  .i2 {
    position: absolute;
    top: 14.125rem;
    left: -0.5%;
    transform: rotate(-11deg);
  }

  .i3 {
    position: absolute;
    top: 26rem;
    left: 20%;
    transform: rotate(6deg);
  }

  .i4 {
    position: absolute;
    top: 24rem;
    left: 38%;
  }

  .i5 {
    position: absolute;
    top: 21rem;
    left: 36%;
  }
}
.banner-carousel {
  margin-left: 3rem;
  .img {
    width: 75%;
    height: 25rem !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01); /* Semi-transparent black */
  z-index: 101; /* Make sure the backdrop is behind the popup */
}

.popup-main {
  background-image: url(http://localhost:3000/static/media/Group%2032993.421d1e1….png);
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000000;
  width: 700px;
  height: 540px;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  transition: opacity 0.01s ease;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px;
}
.popup-visible {
  opacity: 1; /* Make it visible */
}

.close-icon-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.people-img-div {
  width: 210px;
  @include respond(w576) {
    width: 100px;
  }
}
.popup-main-topic {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  @include respond(w576) {
    font-size: 16px;
  }
}
.popup-text-des {
  font-size: 14px;
  font-weight: 100;
  color: #ffffff;
  text-align: center;
  width: 451px;
  margin-top: 10px;
  @include respond(w576) {
    font-size: 10px;
    width: 70%;
  }
}
.email-add {
  background-color: #ffffff;
  @include respond(w576) {
    display: flex;
    //flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98% !important;
    .error-message {
      font-weight: 400;
      font-size: 10px !important;
      line-height: 1.875rem;
      color: #bd0303;
    }
    p {
      font-size: 10px !important;
    }
  }
  .error-message {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.875rem;
    color: #bd0303;
  }
  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.875rem;
    color: #515151;
  }
  .newlater {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.875rem;
    color: #000000;
  }
  .input {
    // position: relative;
    width: 98%;
    padding: 10px 10px 10px 10px;

    input {
      border-radius: 8px !important;
      background-color: #ffffff;
      //border: 1px solid #000000;
      padding: 0.625rem 0.625rem 0.625rem 0.625rem;
      color: #000000;
      width: 98%;
      @include respond(w576) {
        width: 250px;
        padding: 5px;
        border-radius: 4px !important;
      }

      &:focus {
        //border: 1px solid #ffffff;
        box-shadow: none;
      }
    }
  }

  button {
    padding: 0.713rem 1.625rem;
    margin-left: 10px;
    @include respond(w576) {
      width: 70px;
      padding: 9px;
      border-radius: 4px !important;
      font-size: 10px;
      margin-top: 10px;
      margin-bottom: 10px !important;
      margin-left: 10px !important;
    }
  }
}
.whatsapp-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  @include respond(w576) {
    margin-top: 15px;
  }
}
.whatsapp-text {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 8px;
  margin-top: 2px;
}
.slide-show-web {
  width: 100%;
  display: block;
  @include respond(w576) {
    display: none;
  }
}
.slide-show-mob {
  width: 96%;
  display: none;
  @include respond(w576) {
    display: block;
  }
}
